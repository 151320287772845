<template>
	<div>
		<div v-if="(!isTeacher || (isTeacher && isEnLang)) && !isOnProfilePage" class="form-group d-flex align-items-center unfound-school-wrapper">
			<b-form-checkbox v-model="profile.attending_school" id="isInSchool" class="a-checkbox-circle" />
			<label for="isInSchool">
				{{ isTeacher && isEnLang ? $t('Učíte/učili ste tento rok?') :  $t('Si študent/ka na nejakej škole') }}
			</label>
		</div>
			
		<b-row v-if="profile.attending_school || isTeacher || isEnLang" align-v="center">
			<b-col v-if="profile.attending_school || (isTeacher && !isEnLang)" cols="12">
				<z-input-select v-if="!isEnLang" v-model="selectedCity" :options="cities" :optionLabel="cityOptionLabel" :label="`${isTeacher ? $t('Vyberte mesto vašej školy') : $t('Vyber mesto tvojej školy')} *`" :placeholder="$t('Mesto')" />
				<z-input v-else v-model="profile._new_school_name" :label="(isTeacher ? $t('Názov vašej školy') : $t('Názov tvojej školy')) + ' *'" :placeholder="$t('Názov školy')" />
			</b-col>

			<b-col v-if="profile.attending_school || (isTeacher && !isEnLang)" cols="12">
				<label for="schoolAddressAndName" class="label--small">
					{{ $t('Názov ulice a školy') + ' *' }}
					<br/> <span class="text--important">{{ isTeacher ? $t('POZOR! Ak neviete nájsť svoju školu, zadajte najprv ulicu') : $t('POZOR! Ak nevieš nájsť svoju školu, zadaj najprv ulicu') }}</span>
				</label>
				<z-input-select v-if="!isEnLang" v-model="selectedSchool" id="schoolAddressAndName" :options="schoolsOptions" :optionLabel="schoolOptionLabel" :disabled="!selectedCity" :placeholder="$t('Ulica a škola')" /> 
				<z-input v-else v-model="profile._new_school_address" :label="(isTeacher ? $t('Ulica vašej školy') : $t('Ulica tvojej školy')) + ' *'" :placeholder="$t('Ulica školy')" />
			</b-col>
			
			<b-col cols="12">
				<div v-if="!isEnLang" class="form-group d-flex align-items-center unfound-school-wrapper mb-2">
					<b-form-checkbox v-model="didntFindMySchool" id="didntFindMySchool" class="a-checkbox-circle" />
					<label for="didntFindMySchool">
						{{ isTeacher ? $t('Nenašli ste svoju školu?') : $t('Nenašiel/a si svoju školu?') }}
					</label>
				</div>
				<z-input v-else v-model="profile.state" :label="(isTeacher ? $t('Odkiaľ pochádzate?') : $t('Odkiaľ pochádzaš?')) + ' *'" :placeholder="$t('Štát')" />
			</b-col>
		</b-row>

		<b-row v-if="didntFindMySchool && !isEnLang" align-v="center">
			<b-col cols="12">
				<p class="form-text text--important">
					{{ isTeacher ? $t('POZOR! Overte si, či sa škola nenachádza v zozname v predošlej otázke') : $t('POZOR! Over si, či sa škola nenachádza v zozname v predošlej otázke') }}
				</p>
			</b-col>

			<b-col cols="12">
				<z-input v-model="profile._new_school_name" :label="(isTeacher ? $t('Názov vašej školy') : $t('Názov tvojej školy')) + ' *'" :placeholder="$t('Názov školy')" />
			</b-col>

			<b-col cols="12">
				<z-input v-model="profile._new_school_address" :label="(isTeacher ? $t('Ulica vašej školy') : $t('Ulica tvojej školy')) + ' *'" :placeholder="$t('Ulica školy')" />
			</b-col>

			<b-col cols="12">
				<z-input v-model="profile._new_city_name" :label="(isTeacher ? $t('Obec/mesto vašej školy') : $t('Obec/mesto tvojej školy')) + ' *'" :placeholder="$t('Obec/mesto školy')" />
			</b-col>
		</b-row>

		<!-- <b-row v-if="isTeacher" align-v="center">
			<b-col cols="12">
				<z-input v-model="profile.phone" type="tel" :label="$t('Telefónne číslo')" :placeholder="$t('Telefónne číslo')" />
			</b-col>
		</b-row> -->

		<b-row v-if="isTeacher" align-v="end">
			<b-col>
				<b-form-group>
					<label>
						{{ $t('Predmety, ktoré vyučujete tento šk. rok') }} *
						<br>
						<span class="font-italic">{{ $t('Môžete vybrať viacero možností') }}</span>
					</label>
					<multiselect 
						v-model="userSubjectsModel" 
						:options="availableSubjects" 
						:multiple="true" 
						:close-on-select="false" 
						:clear-on-select="false" 
						:preserve-search="false" 
						:preselect-first="false" 
						label="name" 
						track-by="name" 
						selectLabel=""
						placeholder="Vyberte si predmety" 
						selectedLabel="Vybrané"
						deselectLabel="Odstrániť" />
				</b-form-group>

				<b-form-group v-if="containsOptionOther" :label="$t('Iné predmety') + ' *'">
					<b-form-input v-model="profile.subjects_other" type="text" :placeholder="$t('Iné predmety')" />
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import sortBy from 'lodash/sortBy'
import deburr from 'lodash/deburr'
import wAxios from '@/plugins/w/axios'

const api = {
	schoolsAndCities:			() => wAxios.get('v1/enumschool/meta'), // revertnute z get_data('v1/enum/location') kvoli kompatibilite s BE, kde to nie je este idealne vyriesene
	registrationSubjects:	() => wAxios.get_data('v1/enum/registration-subject')
}

export default {

	components: {
		'z-input': 				() => import('@/plugins/appzmudri@component/input/z-input.vue'),
		'z-input-select': () => import('@/plugins/appzmudri@component/input-select/z-input-select.vue'),
		Multiselect
	},

	props: {
		profile: { required: true },
		isOnProfilePage: { default: false }
	},

	data() {
		return {
			didntFindMySchool: false,
			schoolsOptions: [],
			schools: [],
			cities: [],
			apiResponses: null,
			selectedCity: null,
			selectedSchool: null,
			availableSubjects: []
		}
	},

	computed: {
		isTeacher: function() {
			return this.profile.type == 'teacher'
		}, 
		isEnLang: function() {
			return this.$root.$i18n.locale == 'en'
		},
		userSubjectsModel: {
			get () {
				return this.availableSubjects.filter(subject => this.profile.subjects.includes(subject.slug))
			},
			set (newSelectedOptions) {
				this.profile.subjects = newSelectedOptions.map(option => option.slug)   	
			}
		},
		containsOptionOther: function() {
			return this.userSubjectsModel.filter(subject => subject.slug == 'registracia-ine').length > 0
		}
	},

	watch: {
		'profile.type': {
			handler: function(newValue, oldValue) {
				this.didntFindMySchool = false
				if (newValue == 'teacher') {
					this.profile.attending_school = true
				} else if (newValue == 'student' && oldValue == 'teacher') {
					this.profile.attending_school = false
				}
			},
			immediate: true
		},

		selectedCity: async function(newValue, oldValue) {
			if (!newValue) {
				this.selectedSchool = null
				this.profile.city_id = null
				return
			}

			this.profile.city_id = newValue.id
			if (oldValue) {
				this.selectedSchool = null
			}
			await this.apiResponses
			this.schoolsOptions = sortBy(this.schools.filter(school => school.city_id == newValue.id), e => deburr(e.name))
		},

		selectedSchool: function(newValue) {
			this.profile.school_id = newValue && newValue.id
		},
	},
		
	async created() {
		this.apiResponses = Promise.all([
			api.registrationSubjects(),
			api.schoolsAndCities()
		]).then(apiResponses => {
			this.availableSubjects = apiResponses[0]
			this.availableSubjects.sort((subject1, subject2) => {
				let comparison = subject1.sort_order - subject2.sort_order
				if (comparison != 0) {
					return comparison
				}

				return subject1.name.localeCompare(subject2.name)
			})

			this.schools = apiResponses[1].schools.filter(school => this.profile.school_id ? this.profile.school_id == school.id || school.type == 'import' : school.type == 'import')
			this.cities = apiResponses[1].cities.filter(city => this.profile.city_id? this.profile.city_id == city.id || city.type == 'import' : city.type == 'import')
			this.cities = sortBy(this.cities, e => deburr(e.name))

			if (this.profile.city_id) {
				this.selectedCity = this.cities.find(city => city.id == this.profile.city_id)
			}

			if (this.profile.school_id) {
				this.selectedSchool = this.schools.find(school => school.id == this.profile.school_id)
			}
		}).catch(err => {
			this.$wToast.error(err)
		})
	},

	methods: {
		cityOptionLabel(option) {
			return option.name
		},

		schoolOptionLabel(option) {
			return option.name + ', ' + option.address
		}
	},
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.unfound-school-wrapper {
	height: 40px;
	border-radius: 10px;
	padding-left: var(--a-form-group-padding-left);
}

.label--small {
	font-size: 14px;
	padding-left: var(--a-form-group-padding-left);
}

.text--important {
	color: red;
}

.form-text {
	padding-left: 10px;
	margin-bottom: 30px;
}

/* Multi select custom styles */

::v-deep {
	.multiselect__tags {
		border-radius: 0px;
		border: none;
		border-bottom: 1px solid var(--a-color-blue-border);
		font-size: 1.125rem;
		font-weight: bold;
		padding-left: var(--a-form-group-padding-left);
	}
	.multiselect__placeholder {
		color: #9ca1ae;
	}
	.multiselect__tag {
		background: var(--a-color-primary)
	}
	.multiselect__tag-icon {
		line-height: 24px;

		&:hover{
			background: #004bbb;
		}
	}
	.multiselect__tag-icon:after {
			color: white;
	}
}
</style>
